<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline" style="margin-top:10px;">
          <el-form-item label="统计时间" style="float:left;">
            <el-select
              v-model="searchData.timeType"
              placeholder="时间类型"
              @change="changeTimeType"
              style="width: 120px">
              <el-option
                v-for="item in timeTypeArray"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="float:left;">
            <el-date-picker
              :clearable="false"
              :editable="false"
              key="multiSelect"
              style="margin-left: 15px;"
              range-separator="~"
              class="date-picker-pointer"
              value-format="yyyy-MM-dd"
              @change="changeTime"
              v-model="searchData.startTime"
              :picker-options="timeTypeArray[searchData.timeType-1].option"
              :format="timeTypeArray[searchData.timeType-1].format"
              :readonly="timeTypeArray[searchData.timeType-1].readonly"
              :type="timeTypeArray[searchData.timeType-1].type"
              placeholder="选择时间"
              >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
      </template>

      <template v-slot:table="row">
        <el-table
          @sort-change="openSort"
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column label="序号" min-width="5%">
            <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
          </el-table-column>
          <el-table-column
            prop="goodsName"
            label="商品名称"
            min-width="14%">
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="visitorCount"
            label="访客数"
            min-width="8%">
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="viewsCount"
            label="浏览量"
            min-width="8%">
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="payUserNum"
            label="支付人数"
            min-width="10%">
          </el-table-column>
          <el-table-column
            prop="visitorPayRate"
            label="访问-支付转化率"
            min-width="10%">
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="payGoodsNum"
            label="支付件数"
            min-width="10%">
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="payFee"
            label="支付金额（元）"
            min-width="10%">
          </el-table-column>
          <el-table-column
            prop="refundGoodsNum"
            sortable="custom"
            label="成功退款商品数"
            min-width="11%">
          </el-table-column>
          <el-table-column
            prop="refundFee"
            sortable="custom"
            label="成功退款金额（元）"
            min-width="12%">
          </el-table-column>
        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20,50,100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {isEmpty} from '../../../assets/js/utils'
  import {plusDownloadExcelTask} from '../../../store/excel'
  import {getTimeTypeArray} from '../../../assets/js/utils'
  import dayjs from 'dayjs'

  export default {
    name: 'suppGoodsProfile',
    data(){
      return {
        loading: false,
        timeTypeArray:getTimeTypeArray(),
        searchData:{
          startTime: dayjs().startOf('day').subtract(1,'day').format('YYYY-MM-DD'),
          timeType:"1",
          sortField: 1,
          sortType: 2
        },

        sortFieldObj:{
          "visitorCount":1,//访客数量
          "viewsCount":2,//浏览量
          "payUserNum":3,//支付人数
          "payGoodsNum":4,//支付件数
          "payFee":5,//支付金额
          "refundGoodsNum":6,//成功退款商品数
          "refundFee":7,//成功退款金额
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10, // 每页显示得条数
      }
    },
    created(){
      this.getList()
    },
    methods:{
      openSort(attr, rev) {
        this.searchData.sortField = this.sortFieldObj[attr.prop];
        if (attr.order == 'ascending') {
          // 升序
          this.searchData.sortType = 1
        } else if (attr.order == 'descending') {
          this.searchData.sortType = 2
        }
        this.getList();
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {};
        data = Object.assign(data,this.searchData);
        let startTime = this.searchData.startTime;
        let timeType = this.searchData.timeType;
        if(timeType == 1){//自然日
          if(!isEmpty(startTime)){
            startTime = new Date(startTime + " 00:00:00").getTime();
          }
        }else if( timeType == 2){//自然周
          let weekObj = new Date(startTime);
          let date = weekObj.getDay();
          if(date == 1){
            startTime = weekObj.getTime();
          }else{
            startTime = weekObj.getTime() - 24*60*60*1000;
          }
        }else if(timeType == 3){
          startTime = dayjs(new Date(startTime)).startOf("M").format("YYYY-MM-DD");
          startTime = new Date(startTime + " 00:00:00").getTime();
        }

        data.startTime = startTime;
        return data;
      },
      changeTime(){
        this.currentPage = 1;
        this.getList();
      },
      changeTimeType(){
        let timeType = this.searchData.timeType;
        this.currentPage = 1;
        this.searchData.startTime = this.timeTypeArray[timeType-1].value;
        this.getList();
      },
      /**
       * 搜索
       */
      search(){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          data.pageNo = this.currentPage;
          data.pageSize = this.pageSize;
          this.loading = true;
          this.axios.get(URL.suppStatistic.goodsStatisticList,{params:data}).then(res=>{
            this.totalListNum = res.data.total;
            this.tableData = res.data.records;
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },

      /**
       * 导出excel
       */
      downloadExcel(){
        let searchData = this.jointSearchData();
        let data = {
          name:'商品分析导出报表',
          params:{
            excelType:1007,
            excelParameterJson:JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this,data);
      },
    },
  }
</script>

<style lang="less">
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
</style>
<style lang="less" scoped>
  .goods-cover{
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

</style>
